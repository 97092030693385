<template>
  <v-card>
    <v-card-title>
      <span class="title">{{ naam }}</span>
    </v-card-title>

    <v-list class="transparent">
      <v-list-item v-for="geplandItem in planning" :key="geplandItem.omschrijving">
        <v-list-item-title >{{ geplandItem.omschrijving }}</v-list-item-title>

        <!--<v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>-->

        <v-list-item-subtitle class="text-right">
          {{ geplandItem.vak }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-row align="center" justify="end">
        <v-btn
          class="mx-2"
          fab
          dark
          color="indigo"
        >
          <v-icon> mdi-plus</v-icon>
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props:{
        naam: {type: String, required:true},
        planning: {type: Array, required:true}
    }
};
</script>
