<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <dag naam="Maandag" :planning="[{omschrijving:'omschrijving1', vak: 'vak1'}]"/>
          </v-col>
          <v-col cols="12">
            <dag naam="Dinsdag" :planning="[{omschrijving:'omschrijving1', vak: 'vak1'}]"/>
          </v-col>
          <v-col cols="12">
            <dag naam="Woensdag" :planning="[{omschrijving:'omschrijving1', vak: 'vak1'}]"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <dag naam="Donderdag" :planning="[{omschrijving:'omschrijving1', vak: 'vak1'}]"/>
          </v-col>
          <v-col cols="12">
            <dag naam="Vrijdag" :planning="[{omschrijving:'omschrijving1', vak: 'vak1'}]"/>
          </v-col>
          <v-col cols="12">
            <dag naam="Zaterdag/Zondag" :planning="[{omschrijving:'omschrijving1', vak: 'vak1'}]"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Dag from "./Dag";

export default {
  name: "HelloWorld",
  components: {
    Dag,
  },
  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
